import { useNavigate } from '@reach/router'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import isEmpty from 'lodash.isempty'
import React from 'react'
import { CANCEL_SAVE_ACTIONS } from '~/analytics/constants'
import { trackCancellationSave } from '~/analytics/events'
import PanelLayout, {
  PanelCancelOptionOnClick,
} from '~/components/CancelFlowModals/PanelCancelLayout'
import type { CancelPanelProps } from '~/components/CancelFlowPanels/CancelFlowPanel.types'
import { CONFIRM_CANCEL_ROUTE } from '~/components/CancelFlowPanels/StopMySubscriptionButton/StopMySubscriptionButton'
import { TEST_ID } from '~/constants/cypress'
import { ReachRouterHistoryContext } from '~/context/reachRouterHistory'
import { SubscriptionContext } from '~/context/subscription'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import useSelectedOfferBox from './hooks/useSelectedOfferBox'
import type { OfferBoxPanel } from './PanelOfferValueBox.types'
import PanelOfferValueBoxUI from './PanelOfferValueBox.ui'

const PanelOfferValueBox: React.FC<
  Omit<CancelPanelProps, 'panel'> & { panel: OfferBoxPanel }
> = ({ handleModalClose, panel }) => {
  const navigate = useNavigate()
  const { subscription } = React.useContext(SubscriptionContext)
  const showToast = React.useContext(ToastContext)
  const [
    updateSubscription,
    { status: updateSubscriptionStatus },
  ] = mutateSubscription('cancel_flow')
  const { getHistoryEntry } = React.useContext(ReachRouterHistoryContext)
  const selectedBox = useSelectedOfferBox(panel.cancelSaveComponent)

  const [
    checkingForRedirects,
    setCheckingForRedirects,
  ] = React.useState<boolean>(true)

  const { size, type } = subscription.box

  //Handle redirects
  React.useEffect(() => {
    if (selectedBox.type === type && selectedBox.size === size) {
      if (subscription.interval === 'every_2_months') {
        navigate('/delay-prompt', { replace: true })
        return
      } else {
        navigate('/change-frequency-base', { replace: true })
        return
      }
    }
    setCheckingForRedirects(false)
  }, [
    navigate,
    subscription.interval,
    type,
    size,
    selectedBox.type,
    selectedBox.size,
  ])

  const modalOptions: PanelCancelOptionOnClick[] = React.useMemo(
    () => [
      {
        'data-cy': TEST_ID.CANCEL_FLOW_SAVE,
        text: `Yes, switch to the ${selectedBox.name}`,
        onClick: () => {
          updateSubscription(
            {
              ...subscription,
              box: {
                ...subscription.box,
                type: selectedBox.type,
                size: selectedBox.size,
                items: [],
              },
            },
            {
              onSuccess: () => {
                showToast('success', {
                  children: 'Success! Your box has been updated',
                })
                trackCancellationSave(
                  CANCEL_SAVE_ACTIONS.SWITCH_TO_VALUE_BOX,
                  getHistoryEntry(1).pathname,
                  getHistoryEntry(2).state.text
                )
              },
              onError: () =>
                showToast('error', {
                  children:
                    'We had trouble updating your subscription, please contact customer support',
                }),
              onSettled: () => {
                handleModalClose()
              },
            }
          )
        },
        status: updateSubscriptionStatus,
      },
      {
        'data-cy': TEST_ID.CANCEL_FLOW_STOP_SUBSCRIPTION,
        text: 'No, stop my subscription',
        onClick: () => navigate(`/${CONFIRM_CANCEL_ROUTE}`),
        status: updateSubscriptionStatus === 'loading' ? 'disabled' : 'idle',
      },
    ],
    [
      selectedBox,
      updateSubscriptionStatus,
      updateSubscription,
      subscription,
      showToast,
      getHistoryEntry,
      handleModalClose,
      navigate,
    ]
  )

  // avoids flickering while we check if a redirect is needed during mount
  if (checkingForRedirects) return <PanelLayout />

  return isEmpty(selectedBox) ? (
    <LoadingSpinner mx={'auto'} my={0} />
  ) : (
    <PanelOfferValueBoxUI
      modalOptions={modalOptions}
      panel={panel}
      selectedBox={selectedBox}
    />
  )
}

export default PanelOfferValueBox
