import { Grid } from '@chakra-ui/core'
import { rem } from 'design'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { ModalHeader } from 'design/components/Modal/Modal'
import ModalCard from 'design/components/ModalCard/ModalCard'
import { Body, H2 } from 'design/components/Typography/Typography'

import React from 'react'
import PanelLayout, {
  ModalItemSmall,
} from '~/components/CancelFlowModals/PanelCancelLayout'

import { formatPriceFromCents } from '~/utils'
import BoxVariants from '~/utils/boxVariants'
import * as TYPES from './PanelOfferValueBox.types'

const PanelOfferValueBoxUI: React.FC<TYPES.PanelOfferValueBoxUIProps> = ({
  panel,
  modalOptions,
  selectedBox,
}) => {
  return (
    <PanelLayout>
      <ModalHeader pb={rem(8)} variant="bare">
        {panel.headline}
      </ModalHeader>
      <Body pb={rem(24)}>{panel.subhead}</Body>
      <>
        <ModalCard
          alignItems="center"
          d="flex"
          justifyContent="center"
          maxW="100%"
          mb={rem(24)}
          minHeight={rem(200)}
        >
          <H2 color="bb.spicedCrimson" pb={rem(10)}>
            {selectedBox.name}
          </H2>
          {BoxVariants[selectedBox.type].render()}
          <Body>{selectedBox.description}</Body>
          <Body>{selectedBox.poundage}</Body>
          <Body color="bb.spicedCrimson">
            {formatPriceFromCents(selectedBox.price)}
          </Body>
        </ModalCard>

        <Grid gap={rem(16)} gridTemplateColumns="1 fr">
          {modalOptions.map(({ onClick, status, text }, idx) => {
            return (
              <ModalItemSmall
                color={status === 'disabled' ? 'bb.stone' : 'inherit'}
                disabled={status === 'disabled'}
                flexWrap="wrap"
                key={idx}
                onClick={onClick}
              >
                <Body fontSize={rem(14)}>{text}</Body>
                {status === 'loading' && <LoadingSpinner size={'small'} />}
              </ModalItemSmall>
            )
          })}
        </Grid>
      </>
    </PanelLayout>
  )
}

export default PanelOfferValueBoxUI
