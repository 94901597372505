import * as Types from '../PanelOfferValueBox.types'
import { CMS } from '~/cms/types'
import useBoxTypes from '~/hooks/useBoxTypes'
import useBoxSizes from '~/hooks/useBoxSizes'
import find from 'lodash.find'
import pick from 'lodash.pick'
import React from 'react'

const useSelectedOfferBox = function (
  panelSaveComponent: CMS.SaveComponentOfferBox
): Types.SelectedOfferBox {
  const [recommendedSize, setRecommendedSize] = React.useState(null)
  const [recommendedType, setRecommendedType] = React.useState(null)

  const { data: boxSizes, status: sizeStatus } = useBoxSizes('chef_value')
  const { data: boxTypes, status: typeStatus } = useBoxTypes()

  React.useEffect(() => {
    if (typeStatus === 'loading' || sizeStatus === 'loading') {
      return
    }

    const types = boxTypes.filter((ele) => {
      return ele.type === 'chef_value' || ele.type === 'basic_beef_chicken_pork'
    })

    switch (panelSaveComponent) {
      case 'Offer - $99 Box':
        setRecommendedType(
          pick(find(types, ['type', 'basic_beef_chicken_pork']), [
            'type',
            'description',
            'name',
          ])
        )
        setRecommendedSize(
          pick(find(boxSizes, ['poundage', '7-7.5 lbs']), [
            'poundage',
            'price',
            'size',
          ])
        )
        return

      case 'Offer - Chefs Value Box':
        setRecommendedType(
          pick(find(types, ['type', 'chef_value']), [
            'type',
            'description',
            'name',
          ])
        )

        setRecommendedSize(
          pick(find(boxSizes, ['poundage', '12-16 lbs']), [
            'poundage',
            'price',
            'size',
          ])
        )
        return

      default:
        const _exhaustiveCheck: never = panelSaveComponent
        return _exhaustiveCheck
    }
  }, [typeStatus, sizeStatus, boxSizes, boxTypes, panelSaveComponent])

  return { ...recommendedSize, ...recommendedType }
}

export default useSelectedOfferBox
